import React, { useState, useEffect } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../images/logo.png";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useFirebaseApp, useFirestore, useUser } from "reactfire";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby-link";
import Loader from "../../components/Loader";
import ImageUploading from "react-images-uploading";

const EditProduct = ({ location }) => {
  const firebase1 = useFirebaseApp();
  const firestore = useFirestore(firebase1);
  const firebaseStorage = firebase1.storage();
  const fieldValue = useFirestore.FieldValue;

  const [signedIn, setSignedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  let admin = false;
  let signedIn1 = false;

  const { data: user } = useUser();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [uploadProgress1, setUploadProgress1] = useState("");

  let isEditPage = false;
  let productData = {};

  if (location.state != null && location.state != undefined) {
    if (location.state.isFromEdit && location.state.productData) {
      isEditPage = true;
      productData = location.state.productData;
    } else {
      isEditPage = false;
    }
  }

  // if (location.state)
  //   console.log(
  //     "Location Props: " + JSON.stringify(location.state.productData)
  //   );

  const [product1, setProduct1] = useState({
    category: productData.category,
    productName: productData.name,
    dimensions: productData.dimensions,
    finishes: productData.finishes,
    description: productData.description,
    imagesUrl: productData.imagesUrl,
    error: "",
  });

  // let dataActual2 = product1.imagesUrl.map((item, index) => ({
  //   data_url: `${item}`,
  // }));

  let dataActual2 = [];
  product1.imagesUrl.forEach((item, index) => {
    if (item != undefined) {
      const imagesref = firebaseStorage.refFromURL(item);
      const result = imagesref.name.split("-").pop().split(".")[0];
      // console.log("Result112: " + result + "Product: " + product1.name);

      // console.log("Result332: " + result + "Product: " + product1.name);
      dataActual2[result] = {data_url: `${item}`}
    }
  });

  // dataActual2.reverse();
  // console.log("Image Firebase: " + JSON.stringify(dataActual2));

  const [images, setImages] = useState(dataActual2);
  const [images1, setImages1] = useState([]);

  const signOut = () => {
    window.localStorage.setItem("isSignedIn", false);
    navigate("/Login", { state: { from: "signOut" } });
  };

  useEffect(() => {
    if (
      window.localStorage.getItem("isSignedIn") != undefined &&
      window.localStorage.getItem("isSignedIn") == "true"
    ) {
      signedIn1 = true;

      getFirestoreData(user);
    } else {
      navigate("/Login");
    }

    // console.log("Useeffect1 ke andar: "+admin);
  }, [user, window.localStorage.getItem("isSignedIn")]);

  const getFirestoreData = async (user) => {
    if (user != undefined) {
      await firestore
        .collection("Users")
        .where("email", "==", user.email)
        .get()
        .then((snapshot) => {
          if (snapshot.docs[0].data().admin != undefined) {
            // setIsAdmin(true)
            // isAdmin = true
            admin = true;
            // console.log("snapshot ki condtion: true");
          } else {
            // setIsAdmin(false);
            // isAdmin = false;
            admin = false;
            navigate("/Login");
            // console.log("snapshot ki condtion: false");
          }
        });

      setSignedIn(signedIn1);
      setIsAdmin(admin);
    } else {
      return <Loader />;
    }
  };

  const maxNumber = 1000;
  const onChangeImages = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);

    const allImages = Array.from(imageList).map((image) => image.data_url);

    // console.log("Images Array: " + JSON.stringify(allImages));
    setImagesToUpload(allImages);
  };

  // console.log("Product Name: "+product.productName);

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log("Submitted: ");
    let containsFirebaseLinkArray = [];

    // let numberOfFirebaseLinks = 0;
    // images1.map((item) => {
    //   // console.log("Images Data Url " + item.data_url);
    //   if (item.data_url.includes("data:image/jpeg;base64,")) {
    //     numberOfFirebaseLinks--;
    //   } else {
    //     numberOfFirebaseLinks++;
    //   }
    // });

    // images.map((image) => {
    //   if (
    //     image.data_url.includes(
    //       "https://firebasestorage.googleapis.com/v0/b/gs-design-a2ba2.appspot.com"
    //     )
    //   ) {
    //     containsFirebaseLinkArray.push(image.data_url);
    //     console.log("Image554: Firebase Link");
    //   }
    // });

    const productsRef = firestore
      .collection("Products")
      .doc(productData.NO_ID_FIELD);

    //Get image's storage download url and put it into the database
    await productsRef
      .update({
        category: product1.category,
        name: product1.productName,
        dimensions: product1.dimensions,
        finishes: product1.finishes,
        description: product1.description,
        imagesUrl: [],
        timestamp: fieldValue.serverTimestamp(),
        enabled: true,
      })
      .then(() => {
        setUploading(true);
        uploadImagesToStorage(productsRef);
        // navigate("/admin/")
        // console.log("Number of Firebase Links: " + numberOfFirebaseLinks);
        // console.log("Number of All Links: " + images1.length);

        // if (imagesToUpload.length > 0) {
        //   console.log("IMAGES TO UPLOAD");
        //   setUploading(true);
        //   uploadImagesToStorage(productsRef);
        // } else {
        //   if (images1.length > 0) {

        //     // setUploading(true);
        //     setImagesToUpload(images1);
        //     uploadImagesToStorage(productsRef);

        //   } else {
        //     setUploading(false);
        //   }
        // }

        //   if (numberOfFirebaseLinks != images1.length) {
        //     console.log("Updated" + product1);

        //     uploadImagesToStorage(productsRef);
        //   } else {
        //     setUploading(false);
        //     navigate("/admin/");
        //   }
      })
      .catch((error) => {
        alert(error);
        setUploading(false);
      });
  };

  const imagesUrlArray = [];

  const uploadImagesToStorage = async (productsRef) => {
    //Start uploading image to firebase storage
    const allImages = Array.from(images1).map((image) => image.data_url);

    // console.log("IMAGES " + imagesToUpload.length);

    const getDate = new Date();
    const getMiliseconds = getDate.getTime();

    try {
      // console.log("Index12: " + imagesToUpload.length);
      let result = await Promise.all(
        allImages.map((imageToUpload, index) => {
          const storageRef = firebaseStorage.ref(
            `/productImages/${getMiliseconds}-${index}`
          );

          // console.log("Index11: " + JSON.stringify(imageToUpload));

          var metadata = {
            customMetadata: {
              main: false,
            },
          };

          if (index == 0) {
            metadata = {
              customMetadata: {
                main: true,
              },
            };
          }

          let storagePutString = "";
          if (imageToUpload.includes("data:image/jpeg")) {
            storageRef
              .putString(imageToUpload, "data_url", metadata)
              .then((result1) => {
                // console.log("Upload to Storage: " + JSON.stringify(result1));

                const uploadProgress = Math.round(
                  (result1.bytesTransferred / result1.totalBytes) * 100
                );

                setUploadProgress1(uploadProgress);

                // console.log("Progress of Upload: " + uploadProgress);

                if (uploadProgress == 100) {
                  // console.log("Images Map: "+JSON.stringify(map));
                  // console.log(
                  //   "Uploading index: " +
                  //     index +
                  //     " Array length: " +
                  //     imagesToUpload.length
                  // );
                  setUploading(false);

                  // navigate("/admin/")
                } else {
                  setUploading(true);
                }

                updateDownloadUrl(storageRef, "base64").then(
                  (imagesUrlArray1) => {
                    productsRef
                      .update({ imagesUrl: imagesUrlArray1 })
                      .then((result) => {
                        // console.log(
                        //   "Hurrraaaaaaaaay!!!! " + JSON.stringify(result)
                        // );
                        navigate("/admin/");
                      });

                    // const reversedArray = imagesUrlArray1.reverse();
                    // console.log("Get All Urls: " + JSON.stringify(imagesUrlArray1));
                  }
                );

                // setUploading(true);
                // console.log("Upload State2: Running" + uploadProgress);
              });
          } else {
            firebaseStorage
              .refFromURL(imageToUpload)
              .getDownloadURL()
              .then((url) => {
                var filename = url
                  .substring(url.lastIndexOf("/") + 1)
                  .split("?")[0];
                var xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                var imageBlob;
                xhr.onload = function () {
                  imageBlob = xhr.response;
                  var reader = new FileReader();
                  reader.readAsDataURL(imageBlob);
                  var base64data;
                  reader.onloadend = function () {
                    base64data = reader.result;

                    // console.log("Image445545: " + base64data);

                    var metadata = {
                      customMetadata: {
                        main: false,
                      },
                    };

                    if (index == 0) {
                      metadata = {
                        customMetadata: {
                          main: true,
                        },
                      };
                    }

                    storageRef.put(imageBlob, metadata).then((result1) => {
                      // console.log(
                      //   "Upload to Storage: " + JSON.stringify(result1)
                      // );

                      const uploadProgress = Math.round(
                        (result1.bytesTransferred / result1.totalBytes) * 100
                      );

                      setUploadProgress1(uploadProgress);

                      // console.log("Progress of Upload: " + uploadProgress);

                      if (uploadProgress == 100) {
                        setUploading(false);
                      } else {
                        setUploading(true);
                      }

                      updateDownloadUrl1(storageRef, "base64").then(
                        (imagesUrlArray1) => {
                          productsRef
                            .update({ imagesUrl: imagesUrlArray1 })
                            .then((result) => {
                              // console.log(
                              //   "Hurrraaaaaaaaay!!!! " + JSON.stringify(result)
                              // );
                              // console.log(
                              //   "imagesUrlArray1: " +
                              //     imagesUrlArray1.length +
                              //     "allImages: " +
                              //     allImages.length
                              // );
                              if (imagesUrlArray1.length == allImages.length) {
                                navigate("/admin/");
                              }
                            });
                        }
                      );
                    });
                  };

                  let storagePutString = "";
                };

                xhr.open("GET", url);
                xhr.send();
              });
            // // imagesUrlArray.push(imageToUpload);

            // // updateDownloadUrl(storageRef, "firebaseUrl").then(
            // //   (imagesUrlArray1) => {
            // //     productsRef
            // //       .update({ imagesUrl: imagesUrlArray1 })
            // //       .then((result) => {
            // //         console.log(
            // //           "Hurrraaaaaaaaay ayaytayayayayayaya!!!! " +
            // //             JSON.stringify(result)
            // //         );
            // //         navigate("/admin/");
            // //       });
            // //   }
            // // );
          }
        })
      );
    } catch (e) {
      console.log(`error 1: ${e}`);
    }

    // if (imagesToUpload.length > 0) {
    //   // console.log("Array Size1" + imagesToUpload.length);
    // } else {
    //   setUploading(false);
    // }
  };

  const updateDownloadUrl = async (storageRef, type) => {
    return new Promise((resolve, reject) => {
      if (type == "base64") {
        storageRef.getMetadata().then((metadata) => {
          storageRef
            .getDownloadURL()
            .then((result2) => {
              // console.log("Get Download Url: " + result2);

              // console.log(
              //   "Metadata: " +
              //     metadata.customMetadata.main +
              //     " Download Url: " +
              //     result2
              // );

              if (metadata.customMetadata.main) {
                imagesUrlArray.splice(0, 0, result2);
              } else {
                imagesUrlArray.push(result2);
              }

              // console.log(
              //   "Images Url Array: " + JSON.stringify(imagesUrlArray)
              // );
              resolve(imagesUrlArray);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        // console.log("Images url2: " + JSON.stringify(imagesUrlArray));
        resolve(imagesUrlArray);
      }
    });
  };

  const updateDownloadUrl1 = async (storageRef, type) => {
    return new Promise((resolve, reject) => {
      if (type == "base64") {
        storageRef.getMetadata().then((metadata) => {
          storageRef
            .getDownloadURL()
            .then((result2) => {
              // console.log("Get Download Url1: " + result2);

              // console.log(
              //   "Metadata: " +
              //     metadata.customMetadata.main +
              //     " Download Url: " +
              //     result2
              // );

              if (metadata.customMetadata.main) {
                imagesUrlArray.splice(0, 0, result2);
              } else {
                imagesUrlArray.push(result2);
              }

              // console.log(
              //   "Images Url Array1: " + JSON.stringify(imagesUrlArray)
              // );
              resolve(imagesUrlArray);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        // console.log("Images url2: " + JSON.stringify(imagesUrlArray));
        resolve(imagesUrlArray);
      }
    });
  };

  const handleChange11 = (e) => {
    // console.log("PRoduct Name: " + e.target.value);
    setProduct1({ ...product1, [e.target.name]: e.target.value, error: "" });
  };

  // useEffect(() => {
  // console.log("Product: " + JSON.stringify(productData));
  // product1.category = watch("categories", product1.category);
  // product1.productName = watch("name1", product1.productName);
  // product1.dimensions = watch("dimensions", product1.dimensions);
  // product1.finishes = watch("finishes", product1.finishes);
  // product1.description = watch("description", product1.description);

  // console.log("ProductData: " + JSON.stringify(product1));
  // if (true) {
  return (
    <div className="admin2">
      <div className="admin_header">
        {/* <Container> */}
        <Row>
          <Col md="4">
            <div className="admin_logo">
              <img src={Logo} alt="img" />
            </div>
          </Col>
          <Col md="4">
            <div></div>
          </Col>
          <Col md="4">
            <div className="admin_text">
              <p onClick={() => signOut()}>Log Out</p>
            </div>
          </Col>
        </Row>
        {/* </Container> */}
      </div>
      <Row>
        <Col md="2">
          <section className="left_section">
            <div>
              <ul>
                <li>
                  <a href="/admin/">Listed Products</a>
                </li>
                <li>
                  <a href="/admin/AddedProduct/">Add a Product</a>
                </li>
                <li>
                  <a href="/admin/NewRegisters/">Users</a>
                </li>
              </ul>
            </div>
          </section>
        </Col>
        <Col md="10">
          <section className="right_section">
            <div>ADD PRODUCT</div>
            <Row>
              <Col md="6">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {/* <FormGroup>
                    <Label>Product List</Label>
                    <Input
                      type="text"
                      name="ProductList"
                      onChange={handleChange}
                      {...register("category")}
                      placeholder="Product List"
                    />
                  </FormGroup> */}
                  <FormGroup>
                    <Label>Product List</Label>
                    <Input
                      type="select"
                      name="categories"
                      value={product1.category}
                      onChange={handleChange11}
                    >
                      <option value="Select a category">
                        Select a category
                      </option>
                      <option value="Tables & Ottomans">
                        Tables & Ottomans
                      </option>
                      <option value="TV & Media units">TV & Media units</option>
                      <option value="Chairs & Arm Chairs">
                        Chairs & Arm Chairs
                      </option>
                      <option value="Sofas">Sofas</option>
                      <option value="Shelves & Storages">
                        Shelves & Storages
                      </option>
                      <option value="Benches">Benches</option>
                      <option value="Lightings">Lightings</option>
                      <option value="Home Decor">Home Decor</option>
                      <option value="Accessories">Accessories</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Product Name</Label>
                    <Input
                      name="productName"
                      value={product1.productName}
                      onChange={(e) => handleChange11(e)}
                      placeholder="Product Name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Diminesions</Label>
                    <Input
                      className="diminesions_h"
                      type="textarea"
                      name="dimensions"
                      value={product1.dimensions}
                      onChange={handleChange11}
                      placeholder="Diminesions"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Finishes</Label>
                    <Input
                      className="diminesions_h"
                      type="textarea"
                      name="finishes"
                      value={product1.finishes}
                      onChange={handleChange11}
                      placeholder="Finishes"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Description</Label>
                    <Input
                      className="diminesions_h2"
                      type="textarea"
                      name="description"
                      value={product1.description}
                      onChange={handleChange11}
                      placeholder="Description"
                    />
                  </FormGroup>

                  {isUploading ? (
                    <Button className="uplode_btn" disabled>
                      Adding Product...
                    </Button>
                  ) : (
                    <Button
                      className="uplode_btn"
                      onClick={onSubmit}
                      type="submit"
                    >
                      Add Product Now
                    </Button>
                  )}
                </Form>
              </Col>
              <Col md="6">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChangeImages}
                  maxNumber={maxNumber}
                  resolutionType="absolute"
                  resolutionWidth={2000}
                  resolutionHeight={2000}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                  }) => (
                    <div className="upload__image-wrapper">
                      <button
                        className="upload-image-btn"
                        style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Upload Images
                      </button>
                      &nbsp;
                      <button
                        className="remove-all-images-btn"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button>
                      <br />
                      {setImages1(imageList)}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          {index == 0 ? <h5>Main Image</h5> : index + 1}
                          {/* {console.log("Image221: " + JSON.stringify(image))} */}
                          <img src={image.data_url} alt="" width="80px" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="change-image-btn"
                              onClick={() => onImageUpdate(index)}
                            >
                              Change Image
                            </button>
                            <button
                              className="remove-image-btn"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                      {errors && (
                        <div style={{ color: "black" }}>
                          {errors.maxNumber && (
                            <span>
                              Number of selected images exceed maxNumber
                            </span>
                          )}
                          {errors.acceptType && (
                            <span>Your selected file type is not allow</span>
                          )}
                          {errors.maxFileSize && (
                            <span>Selected file size exceed maxFileSize</span>
                          )}
                          {errors.resolution && (
                            <span>
                              The image should have a resolution of 2000 x 2000.
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </ImageUploading>

                {/* {renderImages(selectedImages)}

                <Input
                  type="file"
                  name="uploadImage"
                  className="uplode_btn"
                  multiple
                  {...register("uploadImage")}
                  onChange={imageChangeHandler}
                  placeholder="UPLOAD PRODUCT IMAGE"
                /> */}
              </Col>
            </Row>
            {/* {console.log("Progress: " + uploadProgress1)} */}
            {uploadProgress1 > 0 && uploadProgress1 < 100 ? (
              <h4>Uploading: {uploadProgress1}</h4>
            ) : (
              ""
            )}
          </section>
        </Col>
      </Row>
    </div>
  );
  // } else {
  //   return null;
  // }
};

export default EditProduct;
